import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {borderRadius} from "@pg-design/helpers-css";

export const OfferBoxBase = styled.div`
    ${elevation(2)}
    width: 100%;
    min-width: 288px;
    max-width: 375px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transition: box-shadow 0.2s ease-in-out;
    ${borderRadius(2, 2, 2, 2)}

    &:hover {
        ${elevation(3)}
    }
`;
