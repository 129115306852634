import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";
import {debounce} from "@pg-mono/nodash";

import {getTrackedSiteData} from "./tracked_utils/tracked_site";

const searchTypingHit = delayHit((payload) => hitAlgolytics("search_typing", {...payload, ...getTrackedSiteData()}), 500);

export const debouncedSearchTypingHit = debounce((payload: Object) => {
    return searchTypingHit(payload);
}, 2000);
