import React, {useCallback, useEffect} from "react";
import {useMemo} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button";
import {WideContent} from "@pg-design/grid";
import {borderRadius, display, flexJustifyCenter, h100, mb, mt, mv, onDesktop, p, pb, ph, pl, position, pv, textAlign, w100} from "@pg-design/helpers-css";
import {CloseIcon, DownloadIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import {RequestState, useFetch, useIsMobile} from "@pg-mono/hooks";
import {head, isEmpty} from "@pg-mono/nodash";
import {SkeletonBox} from "@pg-mono/skeleton_box";

import {ApplicationBox} from "../../application/components/ApplicationBox";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {ContactBar} from "../../atoms/contact_bar/ContactBar";
import {ResponseGradeType} from "../../atoms/ContactResponseGrade";
import {GalleryCount} from "../../atoms/GalleryCount";
import {RPModal} from "../../atoms/RPModal";
import {PropertyPlanDisplay} from "../../property/components/PropertyPlanDisplay";
import {PropertyPlanModalContent} from "../../property/components/PropertyPlanModalContent";
import {IPropertyDetail} from "../../property/interfaces/IPropertyDetail";
import {propertyPlan2dTracking} from "../../property/tracking/property_plans2d_tracking";
import {Country} from "../../region/types/Country";
import {ShowPropertyPlanView, showPropertyPlanView} from "../../tracking/algolytics_hits/click_actions/show_property_plan_click";
import {generateHash} from "../../utils/generate_hash";
import {fetchOfferGalleryPictures, fetchPropertyGalleryPictures} from "../actions/fetch_gallery_pictures";
import {OfferGalleryModalContent} from "../detail/components/OfferGalleryModalContent";
import {OfferMapModalContent} from "../detail/components/OfferMapModalContent";
import {IOfferBuilding} from "../detail/types/IOfferBuilding";
import {IOfferMainImage} from "../detail/types/IOfferGallery";
import {OfferDisplayType} from "../helpers/OfferDisplayType";
import {getOfferTypeNameCapital} from "../helpers/OfferType";
import {ISalesOffice} from "../types/ISalesOffice";
import {OfferType} from "../types/OfferType";
import {getOfferDisplayStatus} from "../utils/get_offer_display_status";
import {GalleryModalTabs} from "./GalleryModalTabs";
const presaleOfferDefaultImg375x212 = require("../images/presale_offer_default_img_375x212.jpg");
const presaleOfferDefaultImg = require("../images/presale_offer_default_img.jpg");

export enum ModalActiveTab {
    GALLERY = 1,
    MAP = 2,
    PLAN = 3
}

interface IProps {
    handleGalleryModalChange: (tab: ModalActiveTab) => void;
    modalState: ModalActiveTab | null;
    source: ApplicationSource;
    offer: {
        address: string;
        configuration: {
            display_type: OfferDisplayType;
            is_autoresponder_enabled: boolean;
            limited_presentation?: boolean;
            phone_clip: string | null;
            show_form_placeholder: boolean;
            personal_data_processor_name: string | null;
            personal_data_processor_url: string | null;
            privacy_policy_url: string | null;
            pre_sale?: boolean;
        };
        id: number;
        is_active?: boolean;
        is_phone_clip_enabled: boolean;
        geo_area: {
            coordinates: number[][][];
        };
        geo_point: {
            coordinates: [number, number];
        };
        is_condohotel: boolean;
        is_holiday_location: boolean;
        main_image?: IOfferMainImage | null;
        map_image: string;
        name: string;
        region: {
            country: Country;
            id: number;
            full_name: string;
        };
        sales_offices: ISalesOffice[];
        type: OfferType;
        vendor: {
            configuration: {
                application_external_api: number | null;
                privacy_policy_url: string | null;
                privacy_policy_file: string | null;
            };
            id: number;
            name: string;
            offices: {
                address: string;
                phone: string;
                mobile: string;
                type: number;
                region: {
                    id: number;
                    short_name: string;
                };
            }[];
            slug: string;
            logo: {
                v_log_80x60: string;
                v_log_120x90: string;
                v_log_160x120: string;
            } | null;
        };
    };
    offerBuildings?: IOfferBuilding[];
    property?: IPropertyDetail;
    onPlanImageOpen?: () => void;
    onModalClose?: () => void;
    responseGrade?: ResponseGradeType | null;
    isOpen: boolean;
}

const blankImage = <Image height="760" width={undefined} ratio={{xs: "752:428"}} alt="" />;
const blankImageSmall = <Image height="372" width={undefined} ratio={{xs: "374:210"}} alt="" />;

export const PropertyPlanButton = (props: {propertyPlan: string; onPlanButtonClick: () => void}) => {
    const isMobile = useIsMobile();

    return (
        <a href={props.propertyPlan} target="_blank" css={downloadButton}>
            <Button css={isMobile ? w100 : null} variant="filled_primary" onClick={props.onPlanButtonClick} iconLeft={DownloadIcon}>
                Pobierz rzut
            </Button>
        </a>
    );
};

const placeholder = presaleOfferDefaultImg375x212;
const placeholders = [placeholder, placeholder, placeholder, placeholder, placeholder];

export const GalleryHolder = (props: IProps) => {
    const {offer, property, handleGalleryModalChange, modalState, onModalClose, offerBuildings} = props;
    const isApartment = offer.is_holiday_location || offer.is_condohotel;

    const onClose = () => {
        onModalClose && onModalClose();
    };

    const {fetchStart: fetchOfferGalleryPicture, response: galleryPictures, fetchState} = useFetch(() => fetchOfferGalleryPictures(offer.id));
    const fetchPropertyGalleryPicturesCallback = useCallback(() => {
        if (property?.id) {
            return fetchPropertyGalleryPictures(property.id);
        }
        return Promise.resolve(null);
    }, [property?.id]);

    const {fetchStart: fetchPropertyGalleryPicture, response: propertyGalleryPictures} = useFetch(fetchPropertyGalleryPicturesCallback);

    const isFirstPropertyGalleryImageAvailable = () => {
        return !!(propertyGalleryPictures && propertyGalleryPictures.length > 0 && propertyGalleryPictures[0]?.image);
    };

    useEffect(() => {
        fetchOfferGalleryPicture();
        if (property && property.id) {
            fetchPropertyGalleryPicture();
        }
    }, []);

    const mapImageThumb = (
        <Image
            src={offer.map_image}
            alt={offer.name}
            width="372px"
            height="210px"
            ratio={{
                xs: "760:428"
            }}
        />
    );

    const firstOfferGalleryImage = (
        <Image
            src={galleryPictures?.[0]?.image?.g_img_760x428 || ""}
            alt={offer.name}
            width="760px"
            height={undefined}
            onClick={() => {
                handleGalleryModalChange(ModalActiveTab.GALLERY);
            }}
            ratio={{
                xs: "760:428"
            }}
        />
    );

    const firstPropertyGalleryImage = (
        <Image
            src={propertyGalleryPictures?.[0]?.image?.g_img_155x87 || ""}
            alt={offer.name}
            width="760px"
            height={undefined}
            onClick={() => {
                handleGalleryModalChange(ModalActiveTab.GALLERY);
            }}
            ratio={{
                xs: "760:428"
            }}
        />
    );

    const planImage = head(property?.plan_image_pages || [])?.image.p_img_760x428;
    const propertyPlanImage = (
        <Image
            src={property?.external_plan_url || planImage}
            alt={offer.name}
            width="760px"
            height={undefined}
            onClick={() => {
                handleGalleryModalChange(ModalActiveTab.PLAN);
            }}
            ratio={{
                xs: "760:428"
            }}
            css={propertyPlanImageStyles}
        />
    );
    const firstGalleryImage = isFirstPropertyGalleryImageAvailable() ? firstPropertyGalleryImage : firstOfferGalleryImage;
    const galleryImages = galleryPictures?.map((item) => item && item.image && item.image.g_img_372x210).filter(Boolean) as string[];

    const imagesArray = useMemo(() => {
        if (!galleryPictures) {
            return placeholders;
        }
        if (galleryPictures) {
            return galleryImages;
        }
        return [];
    }, [props.offer.id, galleryPictures]);

    const galleryImgSmallArray = galleryPictures ? imagesArray : placeholders;

    const thumbsGalleryHolder = (
        <ThumbsGalleryHolder>
            {galleryImgSmallArray.slice(0, 4).map((item, idx) => {
                return (
                    item && (
                        <div
                            data-gtm-click="Zobacz galerię"
                            key={idx}
                            css={[thumbGalleryHolder]}
                            onClick={() => {
                                handleGalleryModalChange(ModalActiveTab.GALLERY);
                            }}
                        >
                            {fetchState === RequestState.None || fetchState === RequestState.Waiting ? (
                                <div css={skeletonWrapper}>
                                    <SkeletonBox height="100%" width="100%" />
                                </div>
                            ) : (
                                <Image
                                    src={item}
                                    alt={offer.name}
                                    width="375px"
                                    height={undefined}
                                    ratio={{
                                        xs: "375:210"
                                    }}
                                />
                            )}
                        </div>
                    )
                );
            })}

            {(galleryPictures?.length === 2 || galleryPictures?.length === 3) && (
                <div
                    data-gtm-click="Zobacz mapę"
                    css={[thumbGalleryHolder]}
                    onClick={() => handleGalleryModalChange(ModalActiveTab.MAP)}
                    title="Zobacz na mapie"
                >
                    {mapImageThumb}
                </div>
            )}

            {galleryPictures?.length === 2 && <div css={[thumbGalleryHolder]}>{blankImageSmall}</div>}
        </ThumbsGalleryHolder>
    );

    const offerRightImage = !galleryPictures || galleryPictures?.length >= 1 ? thumbsGalleryHolder : blankImage;
    const rightImage = planImage ? propertyPlanImage : isFirstPropertyGalleryImageAvailable() ? firstGalleryImage : offerRightImage;
    const storeHash = useMemo(() => generateHash(), []);

    const onRightImageClick = () => {
        if (planImage) {
            propertyPlan2dTracking.gtm.openPropertyPlan();
        }
    };

    const renderModalContent = () => {
        switch (modalState) {
            case ModalActiveTab.PLAN:
                return (
                    property && (
                        <>
                            <PropertyPlanDisplay
                                property={property}
                                vendor={offer.vendor}
                                onPlanDownloadClick={onPlanButtonClick}
                                legacyPlan={<PropertyPlanModalContent offerName={offer.name} property={property} onPlanButtonClick={onPlanButtonClick} />}
                            />
                        </>
                    )
                );
            case ModalActiveTab.GALLERY:
                return <OfferGalleryModalContent offer={offer} offerGallery={galleryPictures ?? []} propertyGallery={propertyGalleryPictures ?? []} />;
            case ModalActiveTab.MAP:
                return (
                    <OfferMapModalContent
                        offer={{
                            ...offer,
                            geo_area: {
                                coordinates: {
                                    coordinates: offer.geo_area.coordinates
                                }
                            }
                        }}
                        offerBuildings={offerBuildings}
                        property={property}
                    />
                );
            default:
                return null;
        }
    };

    const trackedData = {
        offer: {
            geo_point: {
                coordinates: offer.geo_point.coordinates
            },
            id: offer.id,
            name: offer.name,
            type: offer.type,
            region: {
                country: offer.region.country,
                full_name: offer.region.full_name,
                id: offer.region.id
            }
        },
        vendor: {
            id: offer.vendor.id,
            name: offer.vendor.name,
            slug: offer.vendor.slug
        },
        property: property && {
            area: property.area,
            discount: property.discount,
            floor: property.floor,
            floors: property.floors,
            id: property.id,
            is_luxury: property.is_luxury,
            is_reserved: property.is_reserved,
            number: property.number,
            plan: property.plan,
            rooms: property.rooms
        }
    };

    const theme = useTheme();

    const isPreSale = !!offer.configuration.pre_sale;
    const isForSale = offer.configuration.display_type === OfferDisplayType.FOR_SALE;
    const isLimited = getOfferDisplayStatus(offer) === "limited";
    const isAutoresponderEnabled = offer.configuration.is_autoresponder_enabled;
    const applicationExternalApi = offer.vendor.configuration.application_external_api;
    const isClassicView = (isForSale || (isLimited && isAutoresponderEnabled)) && !isLimited;
    const isPropertyForSale = property?.for_sale;
    const isMapsTabOpen = props.modalState === ModalActiveTab.MAP;

    const onPlanButtonClick = () => {
        showPropertyPlanView(ShowPropertyPlanView.PLAN_IMAGE_DOWNLOAD, trackedData.offer, trackedData.vendor);
    };

    const offerGalleryLength = galleryPictures?.length || 0;
    const propertyGalleryLength = propertyGalleryPictures?.length || 0;

    const galleryLength = offerGalleryLength + propertyGalleryLength;

    const mainImgSources =
        isPreSale && !offer.main_image
            ? [
                  {minWidthPX: 0, src: presaleOfferDefaultImg375x212, height: 360, width: 448},
                  {minWidthPX: theme.breakpoints.numeric.sm, src: presaleOfferDefaultImg, height: 588, width: 1366}
              ]
            : [
                  {minWidthPX: 0, src: offer.main_image?.m_img_440x248, height: 360, width: 448},
                  {minWidthPX: theme.breakpoints.numeric.xs, src: offer.main_image?.m_img_760x428, height: 600, width: 448},
                  {minWidthPX: theme.breakpoints.numeric.sm, src: offer.main_image?.m_img_983x552, height: 588, width: 1366},
                  {minWidthPX: theme.breakpoints.numeric.md, src: offer.main_image?.m_img_760x428, height: 826, width: 1919}
              ];

    const externalPlanImage = property?.external_plan_url ? [{minWidthPX: 0, src: property.external_plan_url, height: 360, width: 480}] : null;
    const properyPlanImages = property?.plan_image_pages?.[0]?.image
        ? [
              {minWidthPX: 0, src: property?.plan_image_pages[0].image.p_img_375x250, height: 360, width: 448},
              {minWidthPX: theme.breakpoints.numeric.xs, src: property?.plan_image_pages[0].image.p_img_760x428, height: 600, width: 448},
              {minWidthPX: theme.breakpoints.numeric.sm, src: property?.plan_image_pages[0].image.p_img_980, height: 588, width: 1366}
          ]
        : null;
    const mainPlanImage = externalPlanImage || properyPlanImages || mainImgSources;

    return (
        <WideContent>
            <GalleryHolderBase>
                <div
                    css={galleryLeftSideHolder}
                    onClick={() => {
                        handleGalleryModalChange(ModalActiveTab.GALLERY);
                    }}
                >
                    <div>
                        <Picture sources={mainPlanImage} alt={offer.name} css={onDesktop(display("none"))} fetchPriority="high" />

                        <Picture sources={mainImgSources} alt={offer.name} css={mainImageStyle} fetchPriority="high" />
                        <GalleryCount css={imageCounterHolder} photosAmount={galleryLength + 1} />
                    </div>
                </div>

                <GalleryRightSideHolder hasExternalPlan={Boolean(property?.external_plan_url)} onClick={onRightImageClick}>
                    {rightImage}
                </GalleryRightSideHolder>
            </GalleryHolderBase>

            <GalleryModal
                isOpen={props.isOpen}
                onModalClose={onClose}
                closeButton={(closeModal) => <CloseIcon onClick={closeModal} fill="#fff" size="2" css={modalCloseIconHolder} />}
                isMapsTabOpen={isMapsTabOpen}
            >
                <div css={galleryModalImmediateWrapper}>
                    <ModalHeader css={isMapsTabOpen && mapsTabOpenHeaderStyle}>
                        <GalleryModalTabs
                            hasPlanImage={!isEmpty(property?.plan_image_pages)}
                            handleGalleryModalChange={handleGalleryModalChange}
                            modalState={props.modalState || undefined}
                        />
                    </ModalHeader>
                    <div css={galleryModalContent}>
                        {renderModalContent()}

                        <div css={galleryModalFormHolder}>
                            <Text variant="headline_4" as="h1" css={offerInfoTextStyle} mb="1.6rem">
                                <span className="bt">{offer.name}</span>
                                {props.property && ` - ${isApartment ? "Apartament" : getOfferTypeNameCapital(offer.type)} - ${props.property.number}`}
                            </Text>
                            <Text variant="info_txt_1" as="h2" css={offerInfoTextStyle} mb="1.6rem" className="bt">
                                {offer.address}
                            </Text>

                            {((isClassicView && isPropertyForSale) || (!property && isClassicView)) && (
                                <ApplicationBox
                                    storeHash={storeHash}
                                    source={props.source}
                                    sourceSection={modalState === ModalActiveTab.MAP ? ApplicationSourceSection.MAP : ApplicationSourceSection.GALLERY}
                                    vendorId={props.offer.vendor.id}
                                    offerId={props.offer.id}
                                    propertyId={props.property?.id}
                                    isAutoresponderEnabled={offer.configuration.is_autoresponder_enabled}
                                    applicationExternalApi={applicationExternalApi}
                                    optionalVendorInfoData={offer.vendor}
                                    optionalOfferPhoneData={offer}
                                    sidebarBg="dark"
                                />
                            )}
                            {property && property.plan && <PropertyPlanButton onPlanButtonClick={onPlanButtonClick} propertyPlan={property.plan} />}
                        </div>
                    </div>

                    {((isClassicView && isPropertyForSale) || (!property && isClassicView)) && (
                        <ContactBar
                            css={[position("relative"), pv(1), ph(isMapsTabOpen ? 2 : 1)]}
                            salesOffices={offer.sales_offices}
                            vendor={offer.vendor}
                            trackVendor={trackedData.vendor}
                            trackOffer={trackedData.offer}
                            trackProperty={trackedData.property}
                            phoneClip={offer.configuration.phone_clip}
                            isPhoneClipEnabled={offer.is_phone_clip_enabled}
                            backgroundColor="#20212d"
                            source={props.source}
                            isAutoresponderEnabled={offer.configuration.is_autoresponder_enabled}
                            applicationExternalApi={applicationExternalApi}
                            offer={offer}
                            propertyPrice={property?.price}
                            buttonsSize="x-small"
                        />
                    )}
                </div>
            </GalleryModal>
        </WideContent>
    );
};

const GalleryHolderBase = styled.div`
    ${borderRadius(3)};
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.8rem;
    margin-bottom: 1.5rem;

    img {
        cursor: pointer;
        width: 100%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        margin-bottom: 4rem;
        grid-template-columns: 1fr 1fr;
    }
`;

const galleryLeftSideHolder = css`
    position: relative;
`;

const GalleryRightSideHolder = styled.div<{hasExternalPlan: boolean}>`
    ${pl(1)}
    display: none;

    ${onDesktop(css`
        display: initial;
    `)};

    ${({hasExternalPlan}) =>
        hasExternalPlan &&
        css`
            ${p(2)};
            background-color: #fff;
        `};
`;

const ThumbsGalleryHolder = styled.div`
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    column-gap: 1.6rem;
    row-gap: 1.5rem;
`;

const skeletonWrapper = css`
   height: 100%;

    &:after {
        content: "";
        display: block;
        padding-top: 56.25%;
`;

const thumbGalleryHolder = css``;

const imageCounterHolder = css`
    position: absolute;
    bottom: 1.2rem;
    right: 1.2rem;
`;

/*
 * Modal elements
 */

interface GalleryModalProps {
    isMapsTabOpen?: boolean;
}

export const GalleryModal = styled(RPModal)<GalleryModalProps>`
    ${({isMapsTabOpen}) => p(isMapsTabOpen ? 0 : 2)}
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${p(3)}
    }
`;

const galleryModalImmediateWrapper = css`
    display: flex;
    flex: 100%;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    flex: 1 0 auto;
    color: white;
    padding: 2rem 2rem 2.4rem 2rem;
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 2rem 5rem 2.8rem 3rem;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const mapsTabOpenHeaderStyle = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${pb(0)};
        ${ph(0)};
    }
`;

const galleryModalContent = (theme: Theme) => css`
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        padding-bottom: 0;
    }
`;

const downloadButton = (theme: Theme) => css`
    ${onDesktop(mv(3), textAlign("center"))};

    @media (max-width: ${theme.breakpoints.md}) {
        ${flexJustifyCenter};
        ${mt(3)};
        ${mb()};
        ${w100};
    }
`;

export const galleryModalFormHolder = (theme: Theme) => css`
    flex-grow: 0;
    flex-shrink: 0;
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        ${pl(3)}
        display: flex;
        flex-direction: column;
        flex-basis: 33rem;
    }
`;

const offerInfoTextStyle = css`
    color: #fff;
`;
const modalCloseIconHolder = (theme: Theme) => css`
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.md}) {
        right: 3rem;
        top: 3rem;
    }
`;

const propertyPlanImageStyles = css`
    ${h100};

    & > img {
        ${h100};
    }
`;

const mainImageStyle = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;
