import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

const openPropertyPlan = () => {
    const eventData = {event: "gallery_projection_open"};

    hitGoogleTagManager(eventData);
};

const clickOnPropertyPlanRoom = () => {
    const eventData = {
        event: "projecttion_planner",
        projecttion_planner_action: "room_click"
    };

    hitGoogleTagManager(eventData);
};

export const propertyPlan2dTracking = {
    gtm: {
        openPropertyPlan,
        clickOnPropertyPlanRoom
    }
};
