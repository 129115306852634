import {Dispatch} from "redux";

import {isEmpty} from "@pg-mono/nodash";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {fetchOfferList, IOfferQueryResponse, resetOfferList, stopFetchOfferList} from "./fetch_search_offers_action";
import {AutocompletePrediction, optimizedFetchPlaceList, resetPlaceList, stopFetchPlaceList} from "./fetch_search_places_action";
import {fetchRegionList, IRegionListResponse, resetRegionList, stopFetchRegionList} from "./fetch_search_regions_action";
import {fetchVendorList, IVendorListResponse, resetVendorList, stopFetchVendorList} from "./fetch_search_vendors_action";

export enum SearchTab {
    Regions,
    Places,
    Offers,
    Vendors,
    Abroad,
    Holiday
}

const FETCH_ALL_LISTS = "multi-query/FETCH_ALL_LISTS";
export const fetchAllListsType = createRequestActionTypes(FETCH_ALL_LISTS);

export interface IFetchAllSearchListsParams {
    disablePlaceFetch?: boolean;
    stopFetchingAfterRegionList?: boolean;
    regionType?: RegionType;
    country?: Country;
}

// We need to be able to switch tabs always from region down to the places.
// We want to prevent situation in which offers were fetched faster than regions so instead of rendering regions we do render offers.
export const fetchAllSearchLists =
    (searchInput: string, params: IFetchAllSearchListsParams = {}) =>
    (dispatch: Dispatch, getState: () => IRPStore): Promise<SearchTab> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const currentTab = getState().search.currentTab;
            const isAbroadOrHolidayTab = [SearchTab.Abroad, SearchTab.Holiday].some((tab) => tab === currentTab);
            if (isAbroadOrHolidayTab) {
                return;
            }
            dispatch({type: fetchAllListsType.start});

            const regionsPromise = dispatch(
                fetchRegionList(searchInput, {
                    ...(params.regionType ? {type: params.regionType} : {}),
                    ...(params.country ? {country: params.country} : {})
                })
            ).then((res: IRegionListResponse) => {
                if (res && res.results && !isEmpty(res.results)) {
                    // resolve main promise
                    resolve(SearchTab.Regions); // SWITCH to regions
                    return SearchTab.Regions; // regions found
                }
            });

            const offersPromise = dispatch(fetchOfferList(searchInput)).then(async (res: IOfferQueryResponse) => {
                if (res && res.results && !isEmpty(res.results)) {
                    const regionTab = await regionsPromise;
                    if (regionTab != null) {
                        // regions found so do not resolve main promise
                        return true; // offers found
                    }
                    // resolve main promise
                    resolve(SearchTab.Offers); // SWITCH to offers
                    return SearchTab.Offers; // offers found
                }
            });

            const vendorsPromise = dispatch(fetchVendorList(searchInput)).then(async (res: IVendorListResponse) => {
                if (res && res.results && !isEmpty(res.results)) {
                    const regionTab = await regionsPromise;
                    if (regionTab != null) {
                        // regions found so do not resolve main promise
                        return true; // vendors found
                    }
                    const offerTab = await offersPromise;
                    if (offerTab != null) {
                        // offers found so do not resolve main promise
                        return true;
                    }
                    resolve(SearchTab.Vendors);
                    return SearchTab.Vendors;
                }
            });

            // hit places only when all previous lists are empty
            if ((await regionsPromise) != null) {
                dispatch({type: fetchAllListsType.success});
                // regions found so do not resolve main promise
                return;
            }
            if (params.stopFetchingAfterRegionList) {
                if (!params.disablePlaceFetch) {
                    dispatch(optimizedFetchPlaceList(searchInput));
                    dispatch({type: fetchAllListsType.success});
                    resolve(SearchTab.Places);
                }
                return;
            }
            if ((await offersPromise) != null) {
                dispatch({type: fetchAllListsType.success});
                // offers found so do not resolve main promise
                return;
            }
            if ((await vendorsPromise) != null) {
                dispatch({type: fetchAllListsType.success});
                // vendors found so do not resolve main promise
                return;
            }
            if (!params.disablePlaceFetch) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                dispatch(optimizedFetchPlaceList(searchInput)).then(async (predictions: AutocompletePrediction[]) => {
                    // do not check if `predictions` is empty because even so we should land on `PlacesTab`
                });
            }
            // resolve main promise
            resolve(SearchTab.Places); // SWITCH to places

            dispatch({type: fetchAllListsType.success});
            // TODO: timeout to reject
        });
    };

export const stopFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.error});
    dispatch(stopFetchRegionList());
    dispatch(stopFetchPlaceList());
    dispatch(stopFetchOfferList());
    dispatch(stopFetchVendorList());
};

export const resetFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.reset});
    dispatch(resetRegionList());
    dispatch(resetPlaceList());
    dispatch(resetOfferList());
    dispatch(resetVendorList());
};
